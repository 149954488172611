import { environment } from '@box-env/environment';
import { BusinessVertical } from '@box-types';
import {
  jsonScriptId,
  AggresiveRating,
  BreadcrumbList,
  InitFunctonInterface,
  breadcrumbListId,
  aggresiveRatingId,
  BreadcrumbListItem,
  JsonShopType
} from './seo.types';
import { canShowShopRatings } from '../shops';

export {
  getJSONLDElement,
  setJsonLd,
  updateJsonLd,
  removeJsonLdWithId,
  addOrUpdateJsonLd,
  initJsonLd,
  deleteJsonLd,
  createBreadCrumbListItem,
  breadCrumbsItemDecorator,
  getServesCuisineJsonLd,
  getAggresiveRatingObject,
  getJsonShopType,
  getPathWithoutQueryParams,
  removeLastSegment,
  getLastPathSegment
};

function getJSONLDElement(id: string): HTMLScriptElement {
  return document.querySelector(`#${id}`);
}

function setJsonLd(id: jsonScriptId, jsonLd: AggresiveRating | BreadcrumbList) {
  const head = document.querySelector('head');
  const scriptTag = document.createElement('script');
  scriptTag.type = 'application/ld+json';
  scriptTag.id = id;
  const context = JSON.stringify(jsonLd);
  scriptTag.textContent = context;
  head.append(scriptTag);
}

function updateJsonLd(id: jsonScriptId, jsonLd: AggresiveRating | BreadcrumbList) {
  const scriptTag = getJSONLDElement(id);
  const context = JSON.stringify(jsonLd);
  scriptTag.textContent = context;
}

function removeJsonLdWithId(id: string) {
  const jsonScript = getJSONLDElement(id);
  if (jsonScript != null) {
    jsonScript.remove();
  }
}

function addOrUpdateJsonLd(id: jsonScriptId, jsonLd: AggresiveRating | BreadcrumbList) {
  if (jsonLd) {
    if (getJSONLDElement(id)) {
      updateJsonLd(id, jsonLd);
    } else {
      setJsonLd(id, jsonLd);
    }
  }
}

function initJsonLd(jsonLdProps: InitFunctonInterface): void {
  const { breadcrumbs, aggresiveRating } = jsonLdProps;
  addOrUpdateJsonLd(breadcrumbListId, breadcrumbs);
  addOrUpdateJsonLd(aggresiveRatingId, aggresiveRating);
}

function deleteJsonLd() {
  removeJsonLdWithId(breadcrumbListId);
  removeJsonLdWithId(aggresiveRatingId);
}

function createBreadCrumbListItem(position: number, name: string, item: string): BreadcrumbListItem {
  return {
    '@type': 'ListItem',
    position,
    name,
    item
  };
}

function breadCrumbsItemDecorator(list: BreadcrumbListItem[]): BreadcrumbListItem[] {
  const ROOT_URL: string = environment.application.ROOT_URL;
  const newList: BreadcrumbListItem[] = list.map((item, index) => {
    return { ...item, item: `${ROOT_URL}${item.item}`, position: index + 1 };
  });

  return newList;
}

function getServesCuisineJsonLd(shopType: JsonShopType, servesCuisine: string): string {
  if (shopType === 'Restaurant') return servesCuisine;
  return;
}

function getAggresiveRatingObject(
  reviewCount: number,
  ratingValue: number,
  shopType: JsonShopType,
  image: string,
  name: string,
  servesCuisine: string,
  streetAddress: string,
  addressLocality: string,
  postalCode: string
): AggresiveRating {
  if (!canShowShopRatings(reviewCount)) {
    return;
  }

  return {
    '@context': 'https://schema.org/',
    '@type': 'AggregateRating',
    reviewCount: String(reviewCount) ?? undefined,
    ratingValue: String(ratingValue) ?? undefined,
    bestRating: '5',
    worstRating: '1',
    itemReviewed: {
      '@type': shopType,
      image: image ?? undefined,
      name: name ?? undefined,
      servesCuisine: getServesCuisineJsonLd(shopType, servesCuisine),
      address: {
        '@type': 'PostalAddress',
        streetAddress: streetAddress ?? undefined,
        addressLocality: addressLocality ?? undefined,
        postalCode: postalCode,
        addressCountry: 'Greece'
      }
    }
  };
}

function getJsonShopType(businessVertical: BusinessVertical): JsonShopType {
  if (businessVertical === 'groceries') return 'GroceryStore';
  return 'Restaurant';
}

function getPathWithoutQueryParams(path: string): string {
  return path.includes('?') ? path.split('?')[0] : path;
}

function removeLastSegment(path: string): string {
  return path.split('/').slice(0, -1).join('/');
}

function getLastPathSegment(path: string): string {
  return path.split('/').pop();
}
