import {
  PromoCampaign,
  Shop,
  BOXTimeRange,
  PromoCampaignCartSuggestion,
  PromoCampaignType,
  DayOfTheWeek,
  Image,
  PromoVisualOptions
} from '@box-types';
import orderBy from 'lodash-es/orderBy';
import { CollectionCampaign } from '../promo-campaigns';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import { isCurrentTimeInTimeRange } from '../core';
import { omitBy, isNil } from 'lodash-es';

dayjs.extend(duration);
dayjs.extend(isBetween);

export {
  getShopTileMultiplierSum,
  getShopTilePointsSum,
  getSpecialCampaigns,
  getBoxOfferPromoCampaigns,
  filterPromoCampaignsByType,
  filterPromoCampaignsByName,
  promoCampaignHasTextKey,
  filterPromoCampaignsByTextKey,
  promoCampaignHasImageKey,
  filterPromoCampaignsByImageKey,
  getMultiplierSum,
  isProductPromoCampaign,
  isCampaignCartSuggestionEnabled,
  normalizePromoCampaign,
  normalizeCampaignCartSuggestion,
  getPromoCampaignImage,
  getInShopPlacementBannerText,
  getPromoCampaignText,
  getFirstOrderPromoCampaign,
  isPromoCampaignSpecial,
  sortPromoCampaigns,
  getPromoCampaignColor,
  getPointsSum,
  getHotPromoVisuals,
  getPointsPromoVisuals,
  getOfferPromoVisuals,
  findCampaignEnabledTimeRange,
  generateCampaignCountDown,
  generateCampaignCollectionCampaignDialogDescriptionText,
  generateCampaignCollectionCampaignDialogCountdownText,
  getHappyHourExpirationMessage,
  isCollectionCampaign
};

const DEFAULT_WEEKDAYS: DayOfTheWeek[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function filterPromoCampaignsByType(promoCampaigns: PromoCampaign[], type: PromoCampaignType): PromoCampaign[] {
  if (!promoCampaigns || promoCampaigns.length === 0 || !type) return [];
  return promoCampaigns.filter((promoCampaign) => promoCampaign.type === type);
}

function filterPromoCampaignsByName(promoCampaigns: PromoCampaign[], name: string): PromoCampaign[] {
  if (!promoCampaigns || promoCampaigns.length === 0 || !name) return [];
  return promoCampaigns.filter((promoCampaign) => promoCampaign.name === name);
}

function promoCampaignHasTextKey(promoCampaign: PromoCampaign, key: string): boolean {
  if (!promoCampaign.texts?.length) return false;
  return promoCampaign.texts.findIndex((text) => text.key === key) !== -1;
}

function filterPromoCampaignsByTextKey(promoCampaigns: PromoCampaign[], key: string): PromoCampaign[] {
  return promoCampaigns.filter((promoCampaign) => promoCampaignHasTextKey(promoCampaign, key));
}

function promoCampaignHasImageKey(promoCampaign: PromoCampaign, key: string): boolean {
  if (!promoCampaign.images?.length) return false;
  return promoCampaign.images.findIndex((image) => image.key === key) !== -1;
}

function filterPromoCampaignsByImageKey(promoCampaigns: PromoCampaign[], key: string): PromoCampaign[] {
  return promoCampaigns.filter((promoCampaign) => promoCampaignHasImageKey(promoCampaign, key));
}

function getMultiplierSum(promoCampaigns: PromoCampaign[]): number {
  /**
   * NaN ?? 0 => NaN
   * NaN || 0 => 0
   */
  return promoCampaigns.reduce((acc, cur) => acc + (cur?.multiplier || 0), 0);
}

function getPointsSum(promoCampaigns: PromoCampaign[]): number {
  return promoCampaigns.reduce((acc, cur) => acc + (cur?.points || 0), 0);
}

function getShopTileMultiplierSum(promoCampaigns: PromoCampaign[]): number {
  const eligibleCampaigns = promoCampaigns.filter((campaign) => campaign.visibleInShopTile);
  return getMultiplierSum(eligibleCampaigns);
}

function getShopTilePointsSum(promoCampaigns: PromoCampaign[]): number {
  const eligibleCampaigns = promoCampaigns.filter((campaign) => campaign.visibleInShopTile);
  return getPointsSum(eligibleCampaigns);
}

function isProductPromoCampaign(promoCampaign: PromoCampaign): boolean {
  const { type } = promoCampaign;
  return ['product_promo', 'product_cuisine_promo', 'offer_promo', 'collection_drink'].includes(type);
}

function isCampaignCartSuggestionEnabled(campaign: PromoCampaign): boolean {
  if (!campaign?.cartSuggestion) return false;
  if (campaign.cartSuggestion && campaign?.cartSuggestion?.enabled === undefined) return true;
  return campaign.cartSuggestion?.enabled;
}

function normalizeCampaignCartSuggestion(campaign: PromoCampaign): PromoCampaignCartSuggestion {
  if (!campaign?.cartSuggestion) return;
  return {
    enabled: isCampaignCartSuggestionEnabled(campaign),
    enabledOnWeekDays: campaign.cartSuggestion.enabledOnWeekDays ?? DEFAULT_WEEKDAYS,
    ...campaign.cartSuggestion
  };
}

function normalizePromoCampaign(promoCampaign: PromoCampaign): PromoCampaign {
  if (!promoCampaign) return;
  return omitBy(
    {
      ...promoCampaign,
      priority: promoCampaign.priority ?? 0,
      multiplier: promoCampaign.multiplier ?? 0,
      visibleInShopMenu: promoCampaign.visibleInShopMenu === undefined ? true : promoCampaign.visibleInShopMenu,
      visibleInShopTile: promoCampaign.visibleInShopTile === undefined ? true : promoCampaign.visibleInShopTile,
      cartSuggestion: normalizeCampaignCartSuggestion(promoCampaign),
      points: promoCampaign.marketPlacePointsAmount ?? 0 // todo talk with other teams about fixing this
    },
    isNil
  );
}

function getInShopPlacementBannerText(promoCampaign: PromoCampaign, key: string, shop: Shop): string {
  if (!promoCampaign || !key) return;
  const promoText = promoCampaign.texts?.find((t) => t.key === key);
  if (!promoText) return;
  return promoText.text.replace('SHOP_NAME', shop.name);
}

function getPromoCampaignText(promoCampaign: PromoCampaign, key: string): string {
  if (!promoCampaign || !key) return;
  const promoText = promoCampaign.texts?.find((t) => t.key === key);
  return promoText ? promoText.text : null;
}

function getPromoCampaignImage(promoCampaign: PromoCampaign, key: string): Image {
  if (!promoCampaign || !key) return;
  const promoLogo = promoCampaign.images?.find((t) => t.key === key);
  return promoLogo ? promoLogo.image : null;
}

function getPromoCampaignColor(promoCampaign: PromoCampaign, key: string): string {
  if (!promoCampaign || !key) return;
  const promoLogo = promoCampaign.colors?.find((t) => t.key === key);
  return promoLogo ? promoLogo.color : null;
}

function getHotPromoVisuals(): PromoVisualOptions {
  return {
    colors: {
      triangle: 'var(--mode-basic-accent)',
      foreground: 'var(--mode-basic-accent)',
      background: 'var(--colours-shades-accent-shades-accent-subtle)'
    },
    text: 'HOT'
  };
}

function getPointsPromoVisuals(): PromoVisualOptions {
  return {
    colors: {
      triangle: 'var(--mode-basic-accent)',
      foreground: 'var(--mode-basic-accent)',
      background: 'var(--colours-shades-accent-shades-accent-subtle)'
    }
  };
}

function getOfferPromoVisuals(): PromoVisualOptions {
  return {
    colors: {
      triangle: 'var(--mode-basic-accent)',
      foreground: 'var(--mode-basic-accent)',
      background: 'var(--colours-shades-accent-shades-accent-subtle)'
    }
  };
}

// blast, wu
function isPromoCampaignSpecial(campaign: PromoCampaign): boolean {
  if (!campaign.texts?.length) return false;
  const hasShortTag = Boolean(getPromoCampaignText(campaign, 'shortTag'));
  const hasLongTag = Boolean(getPromoCampaignText(campaign, 'longTag'));
  const hasTexts = hasShortTag && hasLongTag;
  const hasBenefits = campaign.multiplier || campaign.points;
  return hasTexts && !hasBenefits;
}

function sortPromoCampaigns(campaigns: PromoCampaign[]): PromoCampaign[] {
  return orderBy(
    campaigns,
    [
      (pc: PromoCampaign) => pc.multiplier ?? 0,
      (pc: PromoCampaign) => pc.points ?? 0,
      (pc: PromoCampaign) => pc.priority ?? 0
    ],
    ['desc', 'desc', 'desc']
  );
}

function getFirstOrderPromoCampaign(shop: Shop, promoCampaigns: PromoCampaign[]): PromoCampaign {
  const firstOrderCampaigns = promoCampaigns
    .filter((pc) => pc.type === 'first_order')
    .filter((pc) => {
      if (!pc.businessVertical) return pc;
      if (pc.businessVertical === shop.businessVertical) return pc;
    });
  if (!firstOrderCampaigns?.length) return;
  return orderBy(firstOrderCampaigns, 'priority', 'desc')[0];
}

function findCampaignEnabledTimeRange(timeRanges: BOXTimeRange[]): BOXTimeRange {
  if (!timeRanges?.length) return;
  return timeRanges.find((tr) => isCurrentTimeInTimeRange(tr));
}

function generateCampaignCountDown(timeRange: BOXTimeRange): number {
  if (!isCurrentTimeInTimeRange(timeRange)) return;

  const currentDate = dayjs();
  const endDate = dayjs()
    .startOf('day')
    .hour(timeRange.endHour)
    .minute(timeRange.endMinute ?? 0);
  return endDate.diff(currentDate, 'second');
}

function generateCampaignCollectionCampaignDialogCountdownText(countdown: number): string {
  if (!countdown) return '1 λεπτού';
  const countDownDuration = dayjs.duration(countdown, 'seconds');
  const days = countDownDuration.days();
  const hours = countDownDuration.hours();
  const minutes = countDownDuration.minutes();
  if (days > 1) return `${days * 24 + hours} ωρών`;
  if (hours >= 2) return `${hours} ωρών`;
  if (hours === 1) return `${hours} ώρας`;
  if (minutes >= 2) return `${minutes} λεπτών`;
  return `1 λεπτού`;
}

function generateCampaignCollectionCampaignDialogDescriptionText(
  campaign: CollectionCampaign,
  couponCode?: string,
  countdown?: number
): string {
  const template = campaign.descriptionTemplate;
  if (!template) return;
  return template
    .replace('BENEFIT_TEXT', `<b>${campaign.descriptionBenefit ?? ''}</b>`)
    .replace('COUPON_CODE', `<b>${couponCode ?? ''}</b>`)
    .replace('EXPIRATION_TEXT', `<b>${generateCampaignCollectionCampaignDialogCountdownText(countdown) ?? ''}</b>`)
    .replace('\n\n', `<br><br>`);
}

function getHappyHourExpirationMessage(promoCampaigns: PromoCampaign[]): string {
  const happyHourCampaign = promoCampaigns.find((c) => c.name === 'happy_hour');
  if (!happyHourCampaign) return;
  const multiplier = happyHourCampaign.multiplier;
  const timeRanges = happyHourCampaign.enabledOnTimeRanges[0];
  const timeFrom = dayjs().set('hour', timeRanges.startHour).set('minute', timeRanges.startMinute).format('HH:mm');
  const timeTo = dayjs().set('hour', timeRanges.endHour).set('minute', timeRanges.endMinute).format('HH:mm');
  return `Μπορείς να παραγγείλεις αύριο μεταξύ ${timeFrom} και ${timeTo} και να κερδίσεις ${multiplier}πλάσιους πόντους!`;
}

function isCollectionCampaign(campaign: PromoCampaign): boolean {
  const hasCampaignModal = campaign.campaignModal?.frequencyInMinutes > 0;
  const hasTabTexts =
    Boolean(getPromoCampaignText(campaign, 'shortTag')) && Boolean(getPromoCampaignText(campaign, 'longTag'));
  return hasCampaignModal && hasTabTexts;
}

function getBoxOfferPromoCampaigns(promoCampaigns: PromoCampaign[]): PromoCampaign[] {
  const boxPromoCampaigns = filterPromoCampaignsByType(promoCampaigns, 'box_offer');
  return boxPromoCampaigns.filter((c) => c.enabled);
}

function getSpecialCampaigns(promoCampaigns: PromoCampaign[]): PromoCampaign[] {
  const specialCampaigns = promoCampaigns.filter(
    (pc) => pc.type !== 'merchant_sponsored' && isPromoCampaignSpecial(pc)
  );
  return sortPromoCampaigns(specialCampaigns);
}
