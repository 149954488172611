<component-loader *ngIf="loading"></component-loader>
<div class="product-myo-wrapper" #wrapper bodyScroll (bodyScroll)="onBodyScroll($event)">
  <div *ngIf="discountSticker" class="product-myo-discount-sticker">{{ discountSticker }}</div>
  <box-dialog-header
    class="product-myo-header"
    [canBack]="canBack"
    [canClose]="canClose"
    [class.product-myo-header-transparent]="!subHeaderStuck"
    [alternativeTheme]="!subHeaderStuck"
    (closeDialog)="onClose()"
    (back)="onBack()"
  ></box-dialog-header>
  <div *ngIf="images?.length || heroImageSrc" class="product-myo-hero-container">
    <product-myo-slider *ngIf="images?.length; else heroImage" [images]="images"></product-myo-slider>
    <ng-template #heroImage>
      <div *ngIf="heroImageSrc" class="product-myo-hero" [style.visibility]="subHeaderStuck ? 'hidden' : 'visible'">
        <figure class="product-myo-hero-image">
          <img [src]="heroImageSrc" [alt]="product.name" />
        </figure>
      </div>
    </ng-template>
    <div
      sectionSlice
      class="product-myo-hero-slice"
      customSliceUrl="/assets/images/bottom-slice-myo.svg"
      sliceShadow="true"
      bottom="-4px"
    ></div>
  </div>
  <div
    stickyObserver
    (stickyChanges)="onSubHeaderStickyChanges($event)"
    class="product-myo-sub-header"
    [class.has-shadow]="subHeaderStuck && bodyScrolled"
  >
    <product-myo-nav
      class="product-myo-nav"
      [reviewsCounter]="reviews?.length"
      [loading]="reviewsLoading"
      [description]="myoTabText"
      (stateChange)="onStateChange($event)"
    ></product-myo-nav>
    <product-myo-details
      *ngIf="state === 'MYO'"
      [isSuperMarket]="isSuperMarket"
      [product]="product"
      [productInstance]="productInstance"
      [showQuantity]="showQuantity"
      [showPrice]="!showOnlyAdditionalPrice"
      [discount]="discount"
      [extraIngredientDiscount]="extraIngredientDiscount"
      (productAdd)="onProductAdd()"
      (productRemove)="onProductRemove()"
    ></product-myo-details>
  </div>
  <main class="product-myo-main">
    <ng-container *ngIf="state === 'MYO'">
      <product-myo-selections
        *ngIf="!isSuperMarket; else superMarketDetails"
        [commentsAllowed]="commentsAllowed"
        [discount]="discount"
        [extraIngredientDiscount]="extraIngredientDiscount"
        [maxLimitType]="maxLimitType"
        [productInstance]="productInstance"
        (optionChange)="onOptionChange()"
        (afterGroupCollapse)="onAfterGroupCollapse()"
        (afterGroupExpand)="onAfterGroupExtend()"
      ></product-myo-selections>
      <ng-template #superMarketDetails>
        <ng-container *ngIf="productDetails?.length">
          <div *ngFor="let detail of productDetails" class="product-myo-details-row">
            <div class="product-myo-details-row-title">{{ detail.title }}</div>
            <div class="product-myo-details-row-description ods-typography-body-s-regular">{{ detail.text }}</div>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>
    <product-myo-reviews *ngIf="state === 'REVIEWS'" [reviews]="reviews"></product-myo-reviews>
  </main>
  <footer
    *ngIf="state === 'MYO'"
    class="product-myo-footer"
    [class.has-shadow]="bodyScrollable && !bodyScrolledToBottom"
  >
    <div class="product-myo-preview" *ngIf="productInstance.description?.length">
      <div class="ods-typography-body-s-bold">Έχεις επιλέξει:</div>
      <div class="product-myo-preview-description ods-typography-body-s-regular">{{ productInstance.description }}</div>
    </div>

    <cart-button
      (click)="onSubmit()"
      class="product-myo-footer-button"
      [showBenefits]="false"
      [disabled]="requiresMakeYourOwn || loading"
      [offerAddition]="showOnlyAdditionalPrice"
      [quantity]="productInstance.quantity"
      [price]="(buttonPrice / 100) * productInstance.quantity"
    >
      {{ buttonText }}
    </cart-button>
  </footer>
</div>
