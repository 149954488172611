<div boxNav [options]="navOptions">
  <div boxNavItem [title]="description" [class.box-nav-item-active]="state === 'MYO'" (click)="onStateChange('MYO')">
    <span class="ods-typography-body-s-bold">{{ description }}</span>
  </div>

  <div
    boxNavItem
    title="Αξιολογήσεις"
    [class.box-nav-item-active]="state === 'REVIEWS'"
    (click)="onStateChange('REVIEWS')"
  >
    <div *ngIf="loading; else reviewsNavTextLoaded" class="product-myo-nav-text-skeleton"></div>
    <ng-template #reviewsNavTextLoaded>
      <span class="ods-typography-body-s-bold">
        Αξιολογήσεις <span>({{ reviewsCounter > 0 ? reviewsCounter : '-' }})</span>
      </span>
    </ng-template>
  </div>
</div>
