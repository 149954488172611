import { BOXTimeRange, DayOfTheWeek } from '@box-types';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

function diffDays(date1: Date, date2: Date): number {
  const [day1, day2] = [date1, date2].map((d) => dayjs(d).startOf('day'));
  return day1.diff(day2, 'day');
}

function dateToCalendar(date: Date): string {
  const currentDate: Date = dayjs().toDate();
  const dateDiff: number = diffDays(date, currentDate);
  if (dateDiff === 0) return 'Σήμερα';
  if (dateDiff === 1) return 'Αύριο';
  return dayjs(date).format('dddd, DD/MM');
}

function filterItemsByTimeRanges<T>(items: (T & { enabled?: boolean; enabledOnTimeRanges?: BOXTimeRange[] })[]): T[] {
  const currentDate = dayjs();
  return items.filter((item) => {
    if (!item.enabled) return false;
    if (!item.enabledOnTimeRanges || item.enabledOnTimeRanges.length === 0) return true;
    const timeRangeDate = item.enabledOnTimeRanges
      .map((range) => {
        const startDate = dayjs().startOf('day').hour(range.startHour).minute(range.startMinute);
        const endDate = dayjs().startOf('day').hour(range.endHour).minute(range.endMinute);
        return { startDate, endDate };
      })
      .find((rangeDates) => currentDate.isBetween(rangeDates.startDate, rangeDates.endDate));
    if (!timeRangeDate) return false;
    return timeRangeDate.endDate.diff(currentDate, 'second');
  });
}

function isCurrentTimeInTimeRange(timeRange: BOXTimeRange): boolean {
  if (!timeRange) return true;
  const { startHour, startMinute = 0, endHour, endMinute = 0 } = timeRange;
  const currentDate = dayjs();
  const startDate = dayjs().startOf('day').hour(startHour).minute(startMinute);
  const endDate = dayjs().startOf('day').hour(endHour).minute(endMinute);
  return currentDate.isAfter(startDate) && currentDate.isBefore(endDate);
}

function isEnabledByTimeRanges(timeRanges: BOXTimeRange[]): boolean {
  // if there are no timeranges we assume it is enabled for the entire day
  if (!timeRanges?.length) return true;
  return timeRanges.some((tr) => isCurrentTimeInTimeRange(tr));
}

function isWithinWeekDays(weekDays: DayOfTheWeek[]): boolean {
  // If there are no weekdays we assume it's enabled for the entire week
  if (!weekDays?.length) return true;
  const weekday = dayjs().locale('en').format('dddd') as DayOfTheWeek;
  if (!weekday) return false;
  return weekDays.includes(weekday);
}

export {
  diffDays,
  dateToCalendar,
  filterItemsByTimeRanges,
  isEnabledByTimeRanges,
  isCurrentTimeInTimeRange,
  isWithinWeekDays
};
