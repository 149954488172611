<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header [title]="'Εξαργύρωσε το κουπόνι σου'" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="checkout-coupons-container">
      <coupon
        *ngFor="let coupon of coupons"
        [active]="selectedCoupon?.code === coupon.code"
        [coupon]="coupon"
        (click)="onCouponClick(coupon)"
      ></coupon>
    </div>

    <footer #dialogFooter class="box-dialog-footer checkout-coupons-dialog-footer">
      <button box-button size="small" theme="secondary" class="checkout-add-coupon" (click)="onCouponAddClick()">
        Εισαγωγή νέου κουπονιού
      </button>
    </footer>
  </main>
</div>
