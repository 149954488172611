import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  BankCardLoyaltyUpdateEvent,
  MarketOrderCheck,
  CheckoutBagsChangeEvent,
  Invoice,
  ProductReplacementOption
} from '@box-types';
import { CheckoutOrderPreview } from '@box-checkout/checkout.types';
import { storageSet, storageGet } from '@box/utils';

const EMPTY_CHECKOUT_ORDER_PREVIEW: CheckoutOrderPreview = {
  cartStartingPrice: 0,
  cartPriceWithPureDiscounts: 0,
  cartPriceWithPureDiscountsBeforeCheckOrderUpdate: 0,
  priceCoveredByCoupon: 0,
  deliveryFee: 0,
  totalEnvFee: 0,
  totalDiscount: 0,
  serviceFee: 0,
  totalPrice: 0,
  tip: 0,
  donation: 0
};

const DEFAULT_POINTS_CHECKED_VALUE = false;
const DEFAULT_POINTS_VALUE: MarketOrderCheck = null;

@Injectable()
export class CheckoutStateService {
  private readonly pointsDiscountSource = new BehaviorSubject<number>(0);
  public readonly pointsDiscount$ = this.pointsDiscountSource.asObservable();

  public readonly bankLoyaltyRedemptionSource = new BehaviorSubject<BankCardLoyaltyUpdateEvent>(null);
  public readonly bankLoyaltyRedemption$ = this.bankLoyaltyRedemptionSource.asObservable();

  private readonly checkoutOrderPreviewSource = new BehaviorSubject<CheckoutOrderPreview>(EMPTY_CHECKOUT_ORDER_PREVIEW);
  public readonly checkoutOrderPreview$ = this.checkoutOrderPreviewSource.asObservable();

  private readonly marketPointsCheckedSource = new BehaviorSubject<boolean>(DEFAULT_POINTS_CHECKED_VALUE);
  public readonly marketPointsChecked$ = this.marketPointsCheckedSource.asObservable();

  private readonly marketOrderCheckSource = new BehaviorSubject<MarketOrderCheck>(DEFAULT_POINTS_VALUE);
  public readonly marketOrderCheck$ = this.marketOrderCheckSource.asObservable();

  private readonly bagsSource = new BehaviorSubject<CheckoutBagsChangeEvent>(null);
  public readonly bags$ = this.bagsSource.asObservable();

  private readonly tipSource = new BehaviorSubject<number>(0);
  public readonly tip$ = this.tipSource.asObservable();

  private readonly donationSource = new BehaviorSubject<number>(0);
  public readonly donation$ = this.donationSource.asObservable();

  private multiplierSum: number;
  private pointsSum: number;

  private readonly invoiceCheckedSource = new BehaviorSubject<boolean>(false);
  public readonly invoiceChecked$ = this.invoiceCheckedSource.asObservable();

  private readonly invoiceSource = new BehaviorSubject<Invoice>(null);
  public readonly invoice$ = this.invoiceSource.asObservable();

  private readonly replacementOptionSource = new BehaviorSubject<ProductReplacementOption>(null);
  public readonly replacementOption$ = this.replacementOptionSource.asObservable();

  private readonly replacementOptionsSource = new BehaviorSubject<ProductReplacementOption[]>([]);
  public readonly replacementOptions$ = this.replacementOptionsSource.asObservable();

  public setReplacementOptions(options: ProductReplacementOption[]): void {
    this.replacementOptionsSource.next(options);
  }

  public resetReplacementOptions(): void {
    this.replacementOptionsSource.next([]);
  }

  public setReplacementOption(option: ProductReplacementOption): void {
    this.replacementOptionSource.next(option);
  }

  public setInvoiceChecked(value: boolean): void {
    this.invoiceCheckedSource.next(value);
  }

  public setInvoice(invoice: Invoice): void {
    this.invoiceSource.next(invoice);
  }

  public clearInvoice(): void {
    this.setInvoice(null);
  }

  public setDonation(tip: number): void {
    this.donationSource.next(tip);
  }

  public getDonation(): number {
    return this.donationSource.getValue();
  }

  public setTip(tip: number): void {
    this.tipSource.next(tip);
  }

  public getTip(): number {
    return this.tipSource.getValue();
  }

  private promoCampaignsShowedSource = new BehaviorSubject<string[]>(
    storageGet('Box:promoCampaignShowed', window.localStorage) ?? []
  );

  public setBagsState(state: CheckoutBagsChangeEvent): void {
    this.bagsSource.next(state);
  }

  public getBagsState(): CheckoutBagsChangeEvent {
    return this.bagsSource.getValue();
  }

  public setMarketPointsChecked(value: boolean): void {
    this.marketPointsCheckedSource.next(value);
  }

  public setMarketOrderCheck(data: MarketOrderCheck): void {
    this.marketOrderCheckSource.next(data);
  }

  public getMarketOrderCheck(): MarketOrderCheck {
    return this.marketOrderCheckSource.getValue();
  }

  public getMarketPointsChecked(): boolean {
    return this.marketPointsCheckedSource.getValue();
  }

  public setPointsSum(value: number): void {
    this.pointsSum = value;
  }

  public getPointsSum(): number {
    return this.pointsSum;
  }

  public setMultiplierSum(value: number): void {
    this.multiplierSum = value;
  }

  public setCheckoutOrderPreview(preview: CheckoutOrderPreview): void {
    this.checkoutOrderPreviewSource.next(preview);
  }

  public getMultiplierSum(): number {
    return this.multiplierSum;
  }

  public getPointsDiscount(): number {
    return this.pointsDiscountSource.getValue();
  }

  public setPointsDiscount(cents: number): void {
    this.pointsDiscountSource.next(cents);
  }

  public getBankLoyaltyRedemption(): BankCardLoyaltyUpdateEvent {
    return this.bankLoyaltyRedemptionSource.getValue();
  }

  public setBankLoyaltyRedemption(bankLoyalty: BankCardLoyaltyUpdateEvent): void {
    this.bankLoyaltyRedemptionSource.next(bankLoyalty);
  }

  public getCheckoutOrderPreview(): CheckoutOrderPreview {
    return this.checkoutOrderPreviewSource.getValue();
  }

  public setPromoCampaignsShowed(names: string[]): void {
    this.promoCampaignsShowedSource.next(names);
    storageSet('Box:promoCampaignShowed', JSON.stringify(names), window.localStorage);
  }

  public getPromoCampaignsShowed(): string[] {
    return this.promoCampaignsShowedSource.getValue();
  }

  public getCartPriceWithPureDiscounts(): number {
    return this.getCheckoutOrderPreview().cartPriceWithPureDiscounts;
  }
}
