import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import {
  FetchShopsOptions,
  Shop,
  FetchShopOptions,
  ShopDetails,
  Address,
  CanDeliverToCoordsResponse,
  APIResponse
} from '@box-types';
import { environment } from '@box-env/environment';
import {
  hasShopAtLeastOneDayOpen,
  normalizeShop,
  isTimestampExpired,
  areAddressesEqual,
  ObservableCacheDecorator
} from '@box/utils';

import dayjs from 'dayjs';
import pickBy from 'lodash-es/pickBy';
import { ShopService } from '@box-core/services/shop.service';

const SHOPS_SESSION_EXPIRATION = 5 * 60 * 1000; // 5 minutes
const SHOP_BY_VANITY_EXPIRATION = 2 * 60 * 1000; // 2 minutes

@Injectable({ providedIn: 'root' })
export class ShopsService {
  private BOX_API: string = environment.application.API_URL;
  private cachedFetchShopsOptions: FetchShopsOptions;
  private shopsTimestamp: number;

  public shops = new BehaviorSubject<Shop[]>([]);

  constructor(private http: HttpClient, private shopService: ShopService) {}

  @ObservableCacheDecorator<Shop[], string>({
    expirationTimeInMs: SHOP_BY_VANITY_EXPIRATION,
    functionKeyArgumentIndexes: [0]
  })
  public fetchShopByVanityUrl$(vanityUrl: string, options?: FetchShopOptions): Observable<Shop> {
    const headers = new HttpHeaders({ 'Accept-Version': '3' });
    const pickedOptions = pickBy(options) as FetchShopOptions;
    const params: HttpParams = new HttpParams({ fromObject: { vanity_url: vanityUrl, ...pickedOptions } });
    return this.http
      .get(`${this.BOX_API}/shops/findShopByVanityUrl`, { params, headers })
      .pipe(map((response: APIResponse<{ shop: Shop }>) => response.payload.shop));
  }

  /*
  Since we migrated to a new URL after the SEO Feature, the old URLs should redirect to the new,
  but we need the locationKey and the new vanity_url. Those are properties that we will get from
  the following endpoint.
  */
  public fetchShopByOldVanityUrl(vanityUrl: string): Observable<Shop> {
    const params: HttpParams = new HttpParams().set('oldVanityUrl', vanityUrl);
    return this.http
      .get(`${this.BOX_API}/shops/findShopByOldVanityUrl`, { params })
      .pipe(map((response: APIResponse<Shop>) => response.payload));
  }

  public fetchShopDetails(shopId: string): Observable<ShopDetails> {
    const params: HttpParams = new HttpParams().set('shopId', shopId);
    return this.http
      .get(`${this.BOX_API}/shops/getShopDetails`, { params })
      .pipe(map((response: APIResponse<{ shop: ShopDetails }>) => response.payload.shop));
  }

  public canDeliverToAddress(shopId: string, address: Address): Observable<CanDeliverToCoordsResponse> {
    const headers = new HttpHeaders({ 'Accept-Version': '2' });
    const url = `/food/canDeliverToCoords/${shopId}/${address.latitude}/${address.longitude}`;
    return this.http.get(this.BOX_API + url, { headers }).pipe(
      map(
        (
          response: APIResponse<{
            shops: CanDeliverToCoordsResponse;
          }>
        ) => response.payload.shops
      )
    );
  }

  public fetchShops(fetchOptions: FetchShopsOptions): Observable<Shop[]> {
    const headers = new HttpHeaders({ 'Accept-Version': '3' });
    const params: HttpParams = this.generateFetchShopsParams(fetchOptions);
    return this.http.get(`${this.BOX_API}/shops`, { params, headers }).pipe(
      map((response: APIResponse<{ shops: Shop[] }>): Shop[] => {
        const shops = response.payload.shops;
        const normalizedShops = shops.map((shop) => normalizeShop(shop));
        const normalizedOpenShops = normalizedShops.filter((shop: Shop) => shop.logo && hasShopAtLeastOneDayOpen(shop));
        return normalizedOpenShops.map((shop) => this.shopService.shopPromoDecorator(shop));
      }),
      tap((shops) => {
        this.cachedFetchShopsOptions = fetchOptions;
        this.shopsTimestamp = dayjs().unix();
        this.setShops(shops);
      })
    );
  }

  public getShops(fetchOptions: FetchShopsOptions): Observable<Shop[]> {
    const hasDiffOptions = !this.fetchOptionsSameAsCached(fetchOptions);
    const expired: boolean = isTimestampExpired(this.shopsTimestamp, SHOPS_SESSION_EXPIRATION);
    if (hasDiffOptions || expired) return this.fetchShops(fetchOptions);
    return this.shops.pipe(take(1));
  }

  public resetShopsTimestamp(): void {
    this.shopsTimestamp = 0;
  }

  public setShops(shops: Shop[]): void {
    this.shops.next(shops);
  }

  public clearShops(): void {
    this.shops.next([]);
  }

  private generateFetchShopsParams(fetchOptions: FetchShopsOptions): HttpParams {
    const { address, guid } = fetchOptions ?? {};
    const { latitude, longitude } = address ?? {};
    const paramsObject: Record<string, string> = {};
    if (latitude) paramsObject.latitude = String(latitude);
    if (longitude) paramsObject.longitude = String(longitude);
    if (guid) paramsObject.guid = String(guid);
    return new HttpParams({ fromObject: paramsObject });
  }

  private fetchOptionsSameAsCached(fetchOptions: FetchShopsOptions): boolean {
    if (!this.cachedFetchShopsOptions) return false;
    const cachedAddress = this.cachedFetchShopsOptions.address;
    const cachedGuid = this.cachedFetchShopsOptions.guid;
    const address = fetchOptions.address;
    const guid = fetchOptions.guid;
    return areAddressesEqual(cachedAddress, address) && cachedGuid === guid;
  }
}
